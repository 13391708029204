import { useEffect } from 'react';
import Client from './api-client';
import { useUserStore } from './User.Store';
import { useCartStore, useShippingStore } from './Cart.Store';


if (!window.location.href.includes('localhost')) {
  Client.setHost(`https://api.${window.location.host}`);
} else {
  Client.setHost('http://localhost:3000');
}

// Client.setHost('https://api.khotiennamsinh.com');
// Client.setHost('https://api.tienkyniem.com');
if (window.location.href.includes('tienkyniem.com')) {
  Client.setHost('https://api.tienkyniem.com');
} else if (window.location.href.includes('shoptiennamsinh.net')) {
  Client.setHost('https://api.shoptiennamsinh.net');
} else if (window.location.href.includes('tiennamsinh.net')) {
  Client.setHost('https://api.tiennamsinh.net');
}

// default to 'http://localhost:3000'
// Client.setHost('https://api.shoptiennamsinh.net');

export const Store = {
  Client,
  Api: Client.Api,
  useUserStore,
  useCartStore,
  useShippingStore,
}

export default Store;