import React, { useState, useEffect } from 'react';
import { Col, Row, Text } from 'components';
import { COLOR, SCREEN, SITE_INFO } from 'const';
import { Entypo, FontAwesome5, Ionicons } from '@expo/vector-icons';
import Store from 'store';
import { DomHelper, VarHelper } from 'helpers';
import { Page } from 'react-facebook';

export const FacebookPage = () => {
    console.log('SITE_INFO', SITE_INFO);
    if (!SITE_INFO.FB_PAGE_URL) return null;
    return (
        <Col borderThin round1 p1 bgWhite shadow mt2>
            <Row mb1 pl1>
                <Entypo name="facebook" size={24} color={COLOR.MAIN} />
                <Text ml2 bold color={COLOR.MAIN}>Facebook Page</Text>
            </Row>
            <Page href={SITE_INFO.FB_PAGE_URL} tabs="timeline" />
            {/* <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpeople%2FShop-ti%25E1%25BB%2581n-n%25C4%2583m-sinh%2F100090753771472%2F%3Fmibextid%3DLQQJ4d&tabs=timeline&width=0&height=0&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=321756205822559"
                style={{ border: 0, overflow: 'hidden', width: '100%', height: 100 }} allowFullScreen allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}

            {/* <div className="fb-page" 
                data-tabs="timeline,events,messages"
                data-href="https://www.facebook.com/shoptiennamsinhnet"
                data-width="250" 
                data-hide-cover="false"></div> */}
        </Col>
    )
};